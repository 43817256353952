import { graphql } from 'gatsby'
import { PrismicDocLinkFrag, PrismicExtLinkFrag } from 'fragments/PrismicLink'

import ContactPage from 'views/contact/ContactPage'

export const query = graphql`
  query ContactPageQuery {
    prismic {
      page: contact_page(uid: "contact", lang: "en-us") {
        page_meta_title
        page_meta_description
        page_meta_thumbnail
        contact_page_title
        body {
          ... on PRISMIC_Contact_pageBodyInfo_section {
            type
            primary {
              title
              subtitle
              content
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
          }
          ... on PRISMIC_Contact_pageBodyContact_boxes {
            type
            fields {
              title
              content
            }
          }
          ... on PRISMIC_Contact_pageBodyParallax_image {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_Contact_pageBodyContact_cta {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              title
              text
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
                ...PrismicLinkFrag
              }
              link_text
            }
          }
        }
      }
    }
  }
`

ContactPage.query = query
ContactPage.fragments = [PrismicDocLinkFrag, PrismicExtLinkFrag]

export default ContactPage
