import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { RichText } from 'prismic'

import { BodyCopy, Subtitle } from 'components/ui/Typography'

import s from './RichTextBox.module.scss'
const cn = classNames.bind(s)

const RichTextBox = ({ data: { title, content } }) => {
  return (
    <div className={cn('wrapper')}>
      {title && <div className={cn('top')}>{<Subtitle>{title[0].text}</Subtitle>}</div>}
      {content && (
        <div className={cn('bottom')}>
          <BodyCopy as="div">{RichText.render(content)}</BodyCopy>
        </div>
      )}
    </div>
  )
}

RichTextBox.propTypes = {
  data: PropTypes.object,
}

export default RichTextBox
