import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import s from './Spacer.module.scss'
const cn = classNames.bind(s)

const getCSSVariables = ({ initial, medium, desktop, large }) => ({
  '--space': initial,
  '--medium-space': medium || initial,
  '--desktop-space': desktop || medium || initial,
  '--large-space': large || desktop || medium || initial,
})

const Spacer = ({
  className,
  initial = '6rem',
  medium = '13.8vw',
  desktop,
  large,
  horizontal = false,
  hideOnMobile = false,
  ...props
}) => (
  <div
    className={cn('spacer', className, !horizontal ? 'vertical' : 'horizontal', { hideOnMobile })}
    style={getCSSVariables({ initial, medium, desktop, large })}
    {...props}
  />
)

Spacer.propTypes = {
  className: PropTypes.string,
  initial: PropTypes.string,
  medium: PropTypes.string,
  desktop: PropTypes.string,
  large: PropTypes.string,
  horizontal: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
}

export default memo(Spacer)
