import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PrismicLink } from 'prismic'

import { Container, Section } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'
import ParallaxImage from 'components/motion/ParallaxImage'
import ViewportEnter from 'components/motion/ViewportEnter'
import { Headline2, BodyCopy } from 'components/ui/Typography'
import Button from 'components/ui/Button'

import classNames from 'classnames/bind'
import s from './CTASection.module.scss'
const cn = classNames.bind(s)

const CTASection = ({ data: { primary } }) => {
  const { image, imageSharp, link, link_text, title, text } = primary
  const [isInView, setIsInView] = useState(false)

  return (
    <Section>
      <Container>
        <Row>
          <Column12 initial={0} desktop={2} />
          <Column12 initial={10} desktop={3} center>
            {imageSharp && (
              <ViewportEnter onEnter={() => setIsInView(true)}>
                <div className={cn('wrapper', { isInView })}>
                  <div className={cn('masks')}>
                    <span className={cn('mask')} />
                    <span className={cn('mask')} />
                  </div>
                  <div className={cn('outer')}>
                    <div className={cn('inner')}>
                      <div className={cn('scaler')}>
                        <ParallaxImage image={imageSharp} alt={image?.alt || title[0].text} />
                      </div>
                    </div>
                  </div>
                </div>
              </ViewportEnter>
            )}
          </Column12>
          <Column12 initial={0} desktop={1} />
          <Column12 initial={10} desktop={4} center>
            <div className={cn('content')}>
              {title && (
                <div className={cn('title')}>
                  <Headline2>{title[0].text}</Headline2>
                </div>
              )}
              <BodyCopy>
                {text && <span className={cn('text')}>{text}</span>}
                {link && (
                  <Button type="underline" to={PrismicLink.url(link)}>
                    {link_text}
                  </Button>
                )}
              </BodyCopy>
            </div>
          </Column12>
          <Column12 initial={0} desktop={2} />
        </Row>
      </Container>
    </Section>
  )
}

CTASection.propTypes = {
  data: PropTypes.object,
}

export default CTASection
