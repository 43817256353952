import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion, useAnimation, useTransform, useSpring, useMotionValue } from 'framer-motion'

/**
 * Parallax element while hovering based on mouse position.
 * Uses framer-motion springs for each property
 */
const PointerParallax = ({ children, style, lerp, transition, disabled }) => {
  const [isHovering, setIsHovering] = useState(false)

  const ref = useRef()
  const position = useAnimation()

  const mouse = useRef({ x: 0, y: 0 })
  const mouseX = useMotionValue(0)
  const mouseY = useMotionValue(0)

  const onMouseMove = (e) => {
    var rect = ref.current.getBoundingClientRect()
    mouse.current.y = -((e.clientY - rect.top) / rect.height) * 2 + 1
    mouse.current.x = ((e.clientX - rect.left) / rect.width) * 2 - 1
    mouseX.set(mouse.current.x)
    mouseY.set(mouse.current.y)
  }

  useEffect(() => {
    isHovering && window.addEventListener('mousemove', onMouseMove)
    !isHovering && mouseX.set(0)
    !isHovering && mouseY.set(0)
    return () => window.removeEventListener('mousemove', onMouseMove)
  }, [isHovering])

  // create a motionvalue spring for each X prop to lerp
  const springs = {}
  Object.keys(lerp.x).forEach((prop) => {
    // motion value lerp
    springs[prop] = useSpring(useTransform(mouseX, [-1, 1], lerp.x[prop]), transition)
  })
  Object.keys(lerp.y).forEach((prop) => {
    // motion value lerp
    springs[prop] = useSpring(useTransform(mouseY, [1, -1], lerp.y[prop]), transition)
  })

  return (
    <motion.div
      className="PointerParallax"
      ref={ref}
      animate={position}
      style={{ ...style, willChange: 'transform', ...springs }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      /*
      transformTemplate={
        ({ y }) => currentStyle === DISBLED_STYLE ? 'none' : `translateY(${y})`
      }
      */
    >
      {children}
    </motion.div>
  )
}

PointerParallax.defaultProps = {
  transition: {
    type: 'spring',
    stiffness: 100,
    damping: 20,
  },
  disabled: false,
  lerp: {
    x: {
      x: ['20%', '-20%'],
    },
    y: {
      y: ['20%', '-20%'],
    },
  },
}

PointerParallax.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  lerp: PropTypes, // x/y props and values to lerp between
  transition: PropTypes.object, // framer-motion transition settings
}

export default PointerParallax
