import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import AspectRatio from 'components/ui/AspectRatio'
import Image from 'components/ui/Image'

import { ScrollParallax } from 'components/motion/parallax'
import useUIContext from 'context/ui'

import classNames from 'classnames/bind'
import s from './ParallaxImage.module.scss'
const cn = classNames.bind(s)

const ParallaxImage = ({ image, delta = '-10%', mediumRatio, alt = '', desktopRatio }) => {
  const src = image?.primary?.imageSharp || image

  const ratio = src?.childImageSharp?.fluid.aspectRatio || 1.778
  const [offset, setOffset] = useState()
  const scrollY = useUIContext((s) => s.scrollY)
  const scrollX = useUIContext((s) => s.scrollX)
  const isHorizontalScrollActive = useUIContext((s) => s.isHorizontalScrollActive)
  const innerRef = useRef(null)
  const altText = image?.primary?.image?.alt || ''

  useEffect(() => {
    if (!innerRef.current) return
    innerRef.current.style[!isHorizontalScrollActive ? 'height' : 'width'] = `${100 + 2 * offset}%`
    innerRef.current.style[!isHorizontalScrollActive ? 'top' : 'left'] = `-${offset}%`
  }, [innerRef, offset])

  useEffect(() => setOffset(Math.abs(parseInt(delta))), [])

  return (
    <div className={cn('wrapper')}>
      <AspectRatio
        className={cn('stretch')}
        ratio={ratio}
        mediumRatio={mediumRatio || ratio}
        desktopRatio={desktopRatio || ratio}
      >
        <ScrollParallax
          key={isHorizontalScrollActive} // make sure we remount when direction changes
          motionValue={!isHorizontalScrollActive ? scrollY : scrollX}
          keyframes={{ [!isHorizontalScrollActive ? 'y' : 'x']: [`-${offset}%`, `${offset}%`] }}
          horizontal={isHorizontalScrollActive}
        >
          <div className={cn('inner')} ref={innerRef}>
            <Image src={src} alt={altText} className={cn('image')} />
          </div>
        </ScrollParallax>
      </AspectRatio>
    </div>
  )
}

ParallaxImage.propTypes = {
  image: PropTypes.object,
  delta: PropTypes.string,
  ratio: PropTypes.number,
  alt: PropTypes.string,
  mediumRatio: PropTypes.number,
  desktopRatio: PropTypes.number,
}

export default ParallaxImage
