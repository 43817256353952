import React from 'react'

import Layout from 'layouts'
import { useWindowWidth } from '@react-hook/window-size'

import { desktop } from 'styles/config/_breakpoints.scss'
import SEO from 'components/seo'
import Spacer from 'components/ui/Spacer'
import { Section } from 'components/layout'
import InfoSection from 'components/ui/InfoSection'
import ParallaxImage from 'components/motion/ParallaxImage'
import CTASection from './components/CTASection'
import ContactBoxesSection from './components/ContactBoxesSection'
import Footer from 'components/ui/Footer'
import VerticalScroll from 'components/layout/VerticalScroll'

const getData = (page, type) => page?.body?.find((i) => i.type === type) || null

const FooterOnlyOnDesktop = () => {
  const windowWidth = useWindowWidth()
  if (windowWidth < parseInt(desktop)) return null
  return <Footer simplifiedFooter />
}

const ContactPage = ({ data: { prismic }, ...props }) => {
  const { page } = prismic
  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail

  return (
    <Layout lightTheme footer={<FooterOnlyOnDesktop />}>
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} lightTheme />
      <VerticalScroll>
        <Spacer initial="8.6255rem" desktop="17vw" />
        <InfoSection data={getData(page, 'info_section')} />
        <Spacer initial="3.1255rem" medium="7vw" />
        <ContactBoxesSection data={getData(page, 'contact_boxes')} />
        <Spacer initial="6rem" medium="13.8vw" />
        <Section>
          <ParallaxImage image={getData(page, 'parallax_image')} />
        </Section>
        <Spacer initial="3.125rem" medium="13.8vw" />
        <CTASection data={getData(page, 'contact_cta')} />
        <Spacer initial="6rem" desktop="19.7vw" />
      </VerticalScroll>
    </Layout>
  )
}

export default ContactPage
