import React from 'react'

import RichTextBox from 'components/ui/RichTextBox'
import Spacer from 'components/ui/Spacer'
import { Container, Section } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'

const ContactBoxesSection = ({ data: { fields } }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Column12 medium={1} desktop={2} large={3} />
          <Column12 initial={10} medium={5} desktop={3} large={2} center>
            {fields[0] && <RichTextBox data={fields[0]} />}
            <Spacer initial="3.125rem" medium="0" />
          </Column12>
          <Column12 medium={0} desktop={2} />
          <Column12 initial={10} medium={5} desktop={3} large={2} center>
            {fields[1] && <RichTextBox data={fields[1]} />}
            <Spacer initial="3.125rem" medium="0" />
          </Column12>
          <Column12 medium={1} desktop={2} large={3} />
        </Row>
        <Spacer initial="0" medium="10vw" desktop="5vw" />
        <Row>
          <Column12 medium={1} desktop={2} large={3} />
          <Column12 initial={10} medium={5} desktop={3} large={2} center>
            {fields[2] && <RichTextBox data={fields[2]} />}
            <Spacer initial="3.125rem" medium="0" />
          </Column12>
          <Column12 medium={0} desktop={2} />
          <Column12 initial={10} medium={5} desktop={3} large={2} center>
            {fields[3] && <RichTextBox data={fields[3]} />}
          </Column12>
          <Column12 medium={1} desktop={2} large={3} />
        </Row>
      </Container>
    </Section>
  )
}

export default ContactBoxesSection
